// mixins
import withScrollTop from "@/mixins/withScrollTop"
import withConfirmation from "@/mixins/withConfirmation"

// misc
import { validateItems } from "@/helpers/matching"
import { isEmpty } from "lodash-es"
import { SERIALIZER_VIEW_EXTENDED } from "@/config/constants"

export default {
  mixins: [withConfirmation, withScrollTop],

  beforeRouteLeave(to, _from, next) {
    this.beforeRouteLeaveHandler({
      to,
      next,
      isChanges: this.isUnsavedItems,
      exitHandler: () => {
        this.resetItems()
        this.resetAddedItems()
        this.resetDeletedItems()
        this.setInvalidItemsIds([])
      }
    })
  },

  watch: {
    isUnsavedItems(useConfirm) {
      this.setLogoutConfirm(useConfirm)
    }
  },

  computed: {
    allowSave() {
      return validateItems(this.items) && validateItems(this.itemsAdded)
    },

    isUnsavedItems() {
      return !isEmpty(this.itemsAdded) || !isEmpty(this.itemsDeleted) || !isEmpty(this.itemsUpdated)
    }
  },

  methods: {
    fetchItems() {
      this.fetchItemsAction({ serializer_view: SERIALIZER_VIEW_EXTENDED })
    },

    handleChangePagination(paginationData) {
      this.$conditionalConfirm({
        useConfirm: this.isUnsavedItems,
        handler: () => this.changePagination(paginationData)
      })
    },

    changePagination(paginationData) {
      this.setPagination({ ...this.pagination, ...paginationData })
      this.resetItems()
      this.resetAddedItems()
      this.resetDeletedItems()
      this.fetchItems()
    },

    handleChangeSorting(newSorting) {
      this.$conditionalConfirm({
        useConfirm: this.isUnsavedItems,
        handler: () => this.changeSorting(newSorting)
      })
    },

    changeSorting(newSorting) {
      this.setSorting(newSorting)
      this.setPagination({ ...this.pagination, current_page: 1 })
      this.resetItems()
      this.resetAddedItems()
      this.resetDeletedItems()
      this.fetchItems()
    },

    handleAddNew() {
      this.addItem()

      this.scrollTo({ target: ".matchings-table-content" })
    }
  }
}
